import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EtatSmsSend.css'; // Import the CSS file
import { useSelector } from 'react-redux';

// Fonction pour formater les dates au format jj-mm-yyyy hh:mm
const formatDate = (dateString) => {
  const date = new Date(dateString);
  
  // Extraire les éléments de la date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const EtatSmsSend = () => {
  const [messageIds, setMessageIds] = useState([]);
  const [dlrData, setDlrData] = useState(null);
  const [filteredDlrData, setFilteredDlrData] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const user = useSelector((state) => state.userReducer.user);
  
  useEffect(() => {
    if (user) {
      setMessageIds(user?.MsgID || []);
    }
  }, [user]);
  
  const MY_KEY = '9484KX59S3SjfxGoUhryB1FiQiyTVUvn5frOad3XntZYSVGGCIpXRSO1Gefpxw2Be0J7KzvOZ8x0VfJ=S8yNvmMw1AsTc1X7AJrLWsd55';

  const dlrStatusMap = {
    DELIVRD: 'تم تسليم الإرسالية بنجاح إلى الفلاح',
    UNDELIV: 'تعتبر هذه الإرسالية غير قابلة للتسليم نهائياً لأن هناك خطأ، وبذلك لن تصل الإرسالية إلى الفلاح',
    EXPIRED: 'للأسف، انتهت المدة المحددة لتسليم الإرسالية أو محاولات تسليمها إلى الفلاح، وبذلك لن تصل الإرسالية إلى الفلاح',
    REJECTED: 'لقد تم رفض الإرسالية من قبل شركة الاتصالات التي يتعامل معها الفلاح. قد تكون الشركة حضرت رقم هاتفه، وبذلك لن تصله الإرسالية',
    UNKNOWN: 'حدث خطأ غير معروف. الرجاء أخذ المعرف والاتصال بشركة تونس س م س',
    SKIPPED: 'تم تجاهل هذه الإرسالية. الرجاء أخذ المعرف والاتصال بشركة تونس س م س',
  };

  const responseStatusMap = {
    200: 'محتوى صحيح',
    400: 'غياب مفتاح التفعيل ',
    401: 'مفتاح التفعيل غير صحيح ',
    402: 'رصيد غير كاف.',
    420: 'تجاوز الحصة اليومية.',
    430: 'المحتوى مفقود.',
    431: 'الوجهة مفقودة.',
    440: 'المحتوى طويل جداً.',
    441: 'الوجهة غير مسموح بها.',
    442: 'عنوان غير صحيح',
    500: 'خطأ داخلي الرجاء أخذ المعرف والاتصال بشركة تونس س م س.',
  };

  const fetchDLR = async () => {
    setError(null);
    try {
      if (messageIds.length === 0) {
        return;
      }
      
      const response = await axios.post('https://app.tunisiesms.tn/api/dlr', {
        type: '55',
        dlr: messageIds.map(id => ({ message_id: id })),
      }, {
        headers: {
          'Authorization': `Bearer ${MY_KEY}`,
        },
      });

      if (Array.isArray(response.data)) {
        const sortedData = response.data.reverse(); // Inverser les données pour que les plus récentes apparaissent en premier
        setDlrData(sortedData);
        setFilteredDlrData(sortedData); // Initialiser les données filtrées
      } else {
        setError('La réponse de l\'API n\'est pas un tableau.');
      }
    } catch (err) {
      setError(err.response ? responseStatusMap[err.response.status] || 'Une erreur est survenue' : 'Une erreur est survenue');
    }
  };

  useEffect(() => {
    if (messageIds.length > 0) {
      fetchDLR();
    }
  }, [messageIds]);

  // Fonction pour filtrer les données en fonction du numéro de téléphone
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    if (dlrData) {
      const filteredData = dlrData
        .filter(item =>
          item.mobile.toLowerCase().includes(searchValue.toLowerCase())
        )
        .reverse(); // Inverser les données filtrées pour que les plus récentes apparaissent en premier

      setFilteredDlrData(filteredData);
    }
  };

  return (
    <div className="container">
      <h1>جدول متابعة الإرساليات لدى شركة التزود تونس س م س </h1>
      {error && <p className="error">Erreur: {error}</p>}
      
      {/* Champ de recherche */}
      <div className="search-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="ابحث حسب رقم هاتف الفلاح ..."
        />
      </div>

      {filteredDlrData && (
        <div className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th>قرار المزود تونس س م س بعد استلامه البيانات</th>
                <th>رقم هاتف الفلاح</th>
                <th>حالة المزود عند إستلام البيانات</th>
                <th>تاريخ بعث الإرسالية</th>
                <th>معرف كل إرسالية</th>
              </tr>
            </thead>
            <tbody>
              {filteredDlrData.map((item, index) => (
                <tr key={index}>
                  <td>{dlrStatusMap[item.dlr] || item.dlr}</td>
                  <td>{item.mobile}</td>
                  <td>{responseStatusMap[item.status] || item.status}</td>
                  <td>{formatDate(item.dlr_time)}</td>
                  <td>{item.message_id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EtatSmsSend;
